<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_147_14960"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_147_14960)"
      />
    </mask>
    <g mask="url(#mask0_147_14960)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_147_14960)"
      />
      <g filter="url(#filter0_d_147_14960)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_147_14960)"
        />
      </g>
      <path
        d="M3.62207 14.4971V16H2.74316V11.7344H4.40723C4.72754 11.7344 5.00879 11.793 5.25098 11.9102C5.49512 12.0273 5.68262 12.1943 5.81348 12.4111C5.94434 12.626 6.00977 12.8711 6.00977 13.1465C6.00977 13.5645 5.86621 13.8945 5.5791 14.1367C5.29395 14.377 4.89844 14.4971 4.39258 14.4971H3.62207ZM3.62207 13.7852H4.40723C4.63965 13.7852 4.81641 13.7305 4.9375 13.6211C5.06055 13.5117 5.12207 13.3555 5.12207 13.1523C5.12207 12.9434 5.06055 12.7744 4.9375 12.6455C4.81445 12.5166 4.64453 12.4502 4.42773 12.4463H3.62207V13.7852ZM7.49512 14.4971V16H6.61621V11.7344H8.28027C8.60059 11.7344 8.88184 11.793 9.12402 11.9102C9.36816 12.0273 9.55566 12.1943 9.68652 12.4111C9.81738 12.626 9.88281 12.8711 9.88281 13.1465C9.88281 13.5645 9.73926 13.8945 9.45215 14.1367C9.16699 14.377 8.77148 14.4971 8.26562 14.4971H7.49512ZM7.49512 13.7852H8.28027C8.5127 13.7852 8.68945 13.7305 8.81055 13.6211C8.93359 13.5117 8.99512 13.3555 8.99512 13.1523C8.99512 12.9434 8.93359 12.7744 8.81055 12.6455C8.6875 12.5166 8.51758 12.4502 8.30078 12.4463H7.49512V13.7852ZM13.7002 12.4463H12.3936V16H11.5146V12.4463H10.2256V11.7344H13.7002V12.4463ZM15.7305 13.2051L16.5303 11.7344H17.541L16.2988 13.8496L17.5732 16H16.5508L15.7305 14.5059L14.9102 16H13.8877L15.1621 13.8496L13.9199 11.7344H14.9307L15.7305 13.2051Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_147_14960"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_147_14960" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_147_14960"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_147_14960"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_147_14960"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DA5D4C" />
        <stop offset="1" stop-color="#E32900" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_147_14960"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF8777" />
        <stop offset="1" stop-color="#F0695F" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
