import { render, staticRenderFns } from "./PPTX.vue?vue&type=template&id=1c1b2529&scoped=true&"
import script from "./PPTX.vue?vue&type=script&lang=js&"
export * from "./PPTX.vue?vue&type=script&lang=js&"
import style0 from "./PPTX.vue?vue&type=style&index=0&id=1c1b2529&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1b2529",
  null
  
)

export default component.exports